<template>
  <div class="wrap">
    <div class="loading" v-show="loading">
      <van-loading size="36px" vertical >
        <div>加载中...</div>
      </van-loading>
    </div>
    <div class="paths">
      <iframe
          v-for="(path,index) in paths"
          :key="index"
          :src="path"
      ></iframe>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      loading: false,
      paths: [],
    }
  },
  created() {
    if (!this.$route.params.orderId || !this.$route.params.oUserId) {
      this.$message({
        message: '订单错误',
        type: 'error',
        duration: 0,
      });
    } else {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.loading = true;
      request({
        url: `message/invoice/${this.$route.params.orderId}/${this.$route.params.oUserId}`,
      })
          .then((res) => {
            if (res.error === 0) {
              this.paths = res.data.paths;
            } else {
              this.$errorMessage(res.message);
            }
          })
          .finally(_ => {
            this.loading = false;
          })
    },
  }
}
</script>

<style scoped lang="less">
.wrap{
  padding: 20px;
}
.paths{
  display: flex;
  flex-direction: column;
  align-items: center;
  iframe{
    width: 90vw;
    height: 45vw;
    margin-bottom: 20px;
  }
}
</style>
